<template>
  <div class='threshold'>
    <v-container>
      <h1 class="page-title mb-3">センサー値補正</h1>
  
      <h2 class="pb-2 pt-10">施設名</h2>
      <div class="pb-10 max-width-480">
        <v-card>
          <v-select class="px-3" v-model="state.selectFacility" item-text="facility_name" item-value="rule_name"
              :items="state.globalFacilityInfoData" @change="changedValueFacility"
              return-object/>
        </v-card>
      </div>

      <h2 class="pb-2 pt-10">センサー値</h2>
      <div class="l-dashboard">
        <v-card color="white" width="240px">
          <v-card-text class="item-flex">
            <v-row class="lighten-4" justify="start" align-content="center">
              <v-col cols=12 class="pa-0">
                <div class="px-1">人数</div>
                <div class="card-user-text px-2">
                  <v-text-field v-model.number="state.people" v-on:blur="checkCongestion1()" class="number-text-field" suffix="人"></v-text-field>
                  <!--<v-text-field v-model.number="state.selectedCongestion1" type="number" v-on:blur="checkCongestion1()" class="number-text-field" suffix="人"></v-text-field>-->
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <div class="pt-5">
        <v-btn v-on:click="doExecUpdate" class="font-bold font-size-20" color="info" x-large width="200px">設定を保存</v-btn>
        <v-progress-linear indeterminate color="info" style="width:200px" v-show="!state.loaded"></v-progress-linear>
      </div>

    </v-container>
  </div>
</template>

<script>
    import { API } from 'aws-amplify';
    import { reactive, onMounted, computed} from '@vue/composition-api';
    import { store } from '../store/store.js';
    import moment from "moment";
    import constants from '../common/constants';

    export default {
        name: 'Threshold',
        components: {
        },
        methods: {
        },
        computed: {
        },
        data() {
            return {
                congestionList1: [],
                congestionList3: [],
            }
        },
        created: async function () {
          await this.getFacilityInfoData()
        },
        mounted: function () {
          console.log(this.state.globalFacilityInfoData)
          let cameraKeyArray = new Array()
          for (var data in this.state.globalFacilityInfoData) {
            cameraKeyArray.push(this.state.globalFacilityInfoData[data]["rule_name"]);
          }
          this.doApiGetLatestPeople(cameraKeyArray)
        },
  
        // Vue Composition API
        setup() {
            const state = reactive({
                globalFacilityInfoData: [],
                selectFacility: null,
                people: null,
                loaded: true,
                store
            })
            const thresholdBetween = computed(() => 
              (
                (Number(state.selectedCongestion1) + 1) > (Number(state.selectedCongestion3) - 1) ? '' :
                (Number(state.selectedCongestion1) + 1) === (Number(state.selectedCongestion3) - 1) ? (Number(state.selectedCongestion1) + 1) + '人' :
                (Number(state.selectedCongestion1) + 1) + "～" + (Number(state.selectedCongestion3) - 1) + "人"
              )
            )

            onMounted(() => {
            })

            /**
             * 閾値の設定を更新反映する。
             */
            function doExecUpdate() {
              state.loaded = false;
              if (state.selectFacility == '' || state.selectFacility == null) {
                alert("施設名を選択してください。");
                state.loaded = true;
                return;
              }
              // if (Number(state.selectedCongestion3) - Number(state.selectedCongestion1) < 2) {
              //   alert("閾値の上限、下限の設定を確認してください。");
              //   state.loaded = true;
              //   return;
              // }

              var updFlg = false;
              var currentServiceProviderId = -1;
              var currentFacilityId = -1;
              for (var data in state.globalFacilityInfoData) {
                if (state.globalFacilityInfoData[data]["rule_name"] == state.selectFacility.rule_name) {
                  currentServiceProviderId = state.globalFacilityInfoData[data]["service_provider_id"];
                  currentFacilityId = state.globalFacilityInfoData[data]["facility_id"];
                  updFlg = true;
                  break;
                }
              }
              if (!updFlg) {
                alert("更新対象がありません。\n更新処理を中断します。");
                state.loaded = true;
                return;
              }

              // API Gatewayの名称
              let apiName = 'iotsstmdevtest201211';
              // API Gatewayの設定パス
              let apiPath = '/ResetLatestPeople';
              // オプション
              let myInit = {
                  headers: {},
                  response: true,
                  body: {
                    service_provider_id: String(currentServiceProviderId),
                    facility_id: String(currentFacilityId),
                    rule_name: String(state.selectFacility.rule_name),
                    people: Number(state.people)
                  }
              };
              // DBデータ更新
              API.post(apiName, apiPath, myInit).then(result => {
                  if (result.data) {
                    alert("更新処理が正常に完了しました。");
                  } else {
                    alert("更新処理に失敗しました。");
                    console.log(result.data.error);
                  }
                  state.loaded = true;
              }).catch(err => {
                  alert("更新処理で想定外エラーが発生しました。");
                  console.log(err);
                  state.loaded = true;
              })
            }

            /**
             * storeから施設情報を取得する。
             */
            function getFacilityInfoData() {
              state.globalFacilityInfoData = [];
              for (var items in store.getFacilityInfoDataStored()) {
                  state.globalFacilityInfoData.push(store.getFacilityInfoDataStored()[items]);
              }
            }
  
            /**
            * 現在人数の取得処理を実行する。
            */
            function doApiGetLatestPeople(cameraKeyArray) {
              let now = moment();
        
              // API Gatewayの名称
              const apiName = 'iotsstmdevtest201211';
              // API Gatewayの設定パス
              const apiPathGetLatestPeople =  '/GetLatestPeopleCustomer';
              const paramPath = apiPathGetLatestPeople;
        
              API.post(
                apiName, paramPath,
                {
                  headers: {},
                  response: true,
                  body: {
                    cameraKey: cameraKeyArray,
                    startDt: moment(now).subtract(constants.NOT_UPDATED_PEOPLE_MINUTES + 1, 'minutes').format(),
                    endDt: moment(now).add(1, 'minutes').format(),
                  }
        
                }).then(response => {
                  console.log(response)
                  let decodedJsonData = JSON.parse(JSON.stringify(response.data));
                  // 現在人数情報のstore保存
                  for (let i in state.globalFacilityInfoData){
                    decodedJsonData.forEach(dataPeople => {
                      if (dataPeople.Items && dataPeople.Items.length && state.globalFacilityInfoData[i].rule_name == dataPeople.Items[0].rule_name) {
                        var newData = state.globalFacilityInfoData[i];
                        newData.people = dataPeople.Items[0].people;
                        newData.report_time = dataPeople.Items[0].report_time;
                        state.globalFacilityInfoData.splice(i, 1, newData);
                      }
                    });
                  }
                }).catch(error => {
                  //alert("現在人数情報の取得処理でエラーが発生しました。");
                  store.clearCurrentPeopleStored();
                  console.log(error);
                })
              }

            /**
             * 施設名プルダウン選択変更時。
             */
            function changedValueFacility() {
              console.log(state.globalFacilityInfoData)
              for (var data in state.globalFacilityInfoData) {
                if (state.globalFacilityInfoData[data]["rule_name"] == state.selectFacility.rule_name) {
                  state.people = state.globalFacilityInfoData[data]["people"] ? state.globalFacilityInfoData[data]["people"] : 0
                  break;
                }
              }
            }

            return {
                state,
                getFacilityInfoData,
                doExecUpdate,
                thresholdBetween,
                changedValueFacility,
                doApiGetLatestPeople
            }
        }
    }
</script>
<style scoped>

.card-user-text {
  font-size: 40px;
  height: 64px;
  line-height: 40px;
  text-align: center;
  padding: 12px 0;
}

.card-status-img {
  border-right: solid 2px #afafaf;
  height: 64px;
  text-align: center;
  padding: 8px 0;
}

.number-text-field {
  font-size: 24px;
}

</style>>