<template>
  <v-app>
  <div v-if="!state.infoGeted">
    <v-app-bar class="head-bar" app>
      <v-toolbar-title class="login-form-head-title">
        <router-link tag="div" to="/" class="head-title-text pointer">
          <img class="pr-1" :src="state.logo" height="36px" />
          <span v-show="!$vuetify.breakpoint.xs" style="vertical-align: top;">混雑状況検知サービス</span>
        </router-link>
      </v-toolbar-title>
      <!--<v-spacer></v-spacer>-->
      <!--<span class="name">{{state.providerName}}</span>-->
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
  <amplify-authenticator>
    <amplify-sign-in slot="sign-in" :hide-sign-up="true"></amplify-sign-in>
    <div>
      <header>
      <v-app-bar class="head-bar" app>
        <v-app-bar-nav-icon @click="drawer = true" class="head-nav-ico"></v-app-bar-nav-icon>
        <v-toolbar-title class="head-title">
          <router-link tag="div" to="/" class="head-title-text pointer">
            <img class="pr-1" :src="state.logo" height="36px" />
            <span v-show="!$vuetify.breakpoint.xs" style="vertical-align: top;">混雑状況検知サービス</span>
          </router-link>
        </v-toolbar-title>
        <!--<v-spacer></v-spacer>-->
        <!--<span class="name">{{state.providerName}}</span>-->
        <v-spacer></v-spacer>
        <v-toolbar-items class="toolbar-items">
          <!--<amplify-sign-out class="btn-sign-out"></amplify-sign-out>-->
          <v-btn class="btn-sign-out mr-1" :class="{'px-0' : $vuetify.breakpoint.xs}" @click="state.passChangeDialog = true" outlined color="info" :width="!$vuetify.breakpoint.xs ? '112px' : '32px'" :min-width="!$vuetify.breakpoint.xs ? '112px' : '32px'">
            {{!$vuetify.breakpoint.xs ? 'パスワード変更' : ''}}
            <v-icon>mdi-key-change</v-icon>
          </v-btn>
          <v-btn class="btn-sign-out" :class="{'px-0' : $vuetify.breakpoint.xs}" @click="signOut" outlined color="info" :width="!$vuetify.breakpoint.xs ? '112px' : '32px'" :min-width="!$vuetify.breakpoint.xs ? '112px' : '32px'">
            {{!$vuetify.breakpoint.xs ? 'サインアウト' : ''}}
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" fixed temporary>
          <v-list-item class="title-background px-3">
            <v-list-item-content>
              <v-list-item-title class="title">
                <v-btn icon @click="drawer = false"><v-icon>mdi-close</v-icon></v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense nav class="pa-0">
            <v-list-item v-for="(menuItem, index) in menuItems" :key="index" :to="menuItem.link" class="menu-item mb-0">
              <v-list-item-content>
                <v-list-item-title>
                  {{ menuItem.name }}
                  <v-icon class="menu-icon">mdi-chevron-right</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
      </v-navigation-drawer>
      
      <v-dialog max-width="360px" persistent v-model="state.passChangeDialog">
        <v-card id="">
          <v-card-title class="pa-0">
            <div class="width-100-p">
              <v-toolbar flat color="info" dark class="px-5">
                <v-toolbar-title>
                  <span id="" class="" style="color: white">パスワード変更</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn id="" icon @click="state.passChangeDialog = false;$refs.changePassForm.reset();state.showNewPassword = false;state.showNowPassword = false;state.showNewPasswordConfirm = false"><v-icon size="36px">mdi-close</v-icon></v-btn>
              </v-toolbar>
            </div>
          </v-card-title>
          <v-card-text>
            <v-form ref="changePassForm" v-model="state.changePassForm">
              <div class="pb-1 mt-10">
                <v-text-field
                  id="nowPassword"
                  v-model="state.nowPass"
                  :type="state.showNowPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-icon="state.showNowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="現在のパスワードを入力"
                  @click:append="state.showNowPassword  = !state.showNowPassword "
                  :rules="[state.rules.required]"
                />
                <v-text-field
                  id="newPassword"
                  v-model="state.newPass"
                  :type="state.showNewPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-icon="state.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="新しいパスワードを入力"
                  @click:append="state.showNewPassword  = !state.showNewPassword "
                  :rules="[state.rules.required, state.rules.password]"
                />
                <v-text-field
                  id="newPasswordConfirm"
                  v-model="state.newPassConfirm"
                  :type="state.showNewPasswordConfirm ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-icon="state.showNewPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  label="再度新しいパスワードを入力"
                  @click:append="state.showNewPasswordConfirm  = !state.showNewPasswordConfirm "
                  :rules="[state.rules.required, state.rules.passConfirm]"
                />
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-0">
            <div class="width-100-p text-center pb-5 pt-3">
              <v-btn id="" :disabled="!state.changePassForm" @click="passChange()" width="112px" depressed color="info" class="font-bold mx-2">変更する</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </header>
  
      <v-main class="padding-top-64">
        <router-view class="pt-4" v-if="state.infoGeted" />
      </v-main>
    </div>
</amplify-authenticator>
  </v-app>
</template>




<style type="text/css">

/* --------------------------
Main
----------------------------*/
.v-application--wrap {
	background: #ECEEF1 !important;
}

.container {
	box-sizing: border-box;
}

.l-dashboard {
  margin-bottom: 20px;
}

img.guide {
  max-width: 500px;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.page-title {
	border-bottom: solid 2px #afafaf;
}

.border-bottom {
	border-bottom: solid 1px #afafaf;
}

.border-bottom-info {
	border-bottom: solid 2px #2196f3;
}

.border-right {
	border-right: solid 1px #afafaf;
}

.width-100-p {
	width: 100% !important;
}

.width-50-p {
	width: 50% !important;
}

.max-width-480 {
	max-width: 480px !important;
}

.dialog-title {
	border-bottom: solid 1px #afafaf;
}

.font-size-28 {
	font-size: 28px !important;
}

.font-size-20 {
	font-size: 20px !important;
}

.font-size-16 {
	font-size: 16px !important;
}

.font-size-14 {
	font-size: 14px !important;
}

.item-inline {
	display: inline-block !important;
}

.item-flex {
	display: flex !important;
}

.font-color-info {
	color: #2196f3;
}

.border-box-info {
	border: solid 1px #2196f3;
}

.border-box {
	border: solid 1px;
}

.font-bold {
	font-weight: bold !important;
}

.number-text-field input{
  text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.login-form-logo {
  width: 100%;
  top: 160px;
  position: fixed;
}

.login-form-logo-inner {
  background-color: white;
  width: 460px;
}

.padding-top-64 {
  padding-top: 64px !important;
}

/* --------------------------
Header
----------------------------*/
header {
	height: auto !important;
}

header span.name {
  font-size: 0.8rem;
}

.head-bar {
	background: #fff !important;
	color: #424242 !important;
}

.v-toolbar__content {
	height: auto !important;
}

.v-toolbar__title {
	color: #424242;
	font-weight: bold;
	margin-left: 0 !important;
}

.login-form-head-title {
  height: 48px !important;
}

.head-title {
  border-left: solid 2px #eeeeee;
  height: 48px !important;
}

.head-title-text {
	padding-top: 8px;
}

.head-nav-ico i {
	color: #424242 !important;
	height: 36px !important;
	width: 36px !important;
	font-size: 36px !important;
}

h2 {
	color: #414141;
	font-size: 22px;
	padding: 20px 20px 20px 0;
}

.flx-block-content {
	font-size: 12px;
	line-height: 1.7;
}

.flx {
	display: flex !important;
}

.v-data-table-header{
	background-color: #F2F2F2;
}

/*.btn-sign-out {*/
/*	width: 120px;*/
/*	margin-right: 28px;*/
/*}*/

.btn-sign-out {
	border-radius: 4px !important;
	font-weight: bold !important;
}

.toolbar-items {
	height: 32px !important;
}

/* --------------------------
SideMenu
----------------------------*/

.title {
	text-align: right;
}

.title-background {
	background-color: #efefef;
	height: 36px !important;
	border-bottom: solid 2px #afafaf;
}

.menu-item {
	padding: 0px 16px !important;
	margin: 0px;
	border-bottom: solid 2px #afafaf;
	border-radius: 0px !important;
	height: 52px;
}

.menu-item div {
	font-size: 16px !important;
}

.menu-icon {
	position: absolute !important;
	right: 16px;
	top: 14px;
	color: #6f6f6f !important;
}

</style>


<script>
import { Auth } from 'aws-amplify';
import { reactive, computed, onMounted} from '@vue/composition-api';
import { store } from './store/store.js';
import constants from './common/constants';
import Logo from '@/assets/logo.png';

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    menuItems: constants.menuItems,
  }),

  // Vue Composition API
  setup() {
    const state = reactive({
      // private
      privateServiceProviderId: -1,
      // grobal
      globalFacilityInfoData: {},

      providerName: computed(
        () => (Object.values(state.globalFacilityInfoData)[0]) ? Object.values(state.globalFacilityInfoData)[0]["service_provider_name"] : ""
      ),
      infoGeted: false,
      passChangeDialog: false,
      nowPass: '',
      newPass: '',
      newPassConfirm: '',
      showNowPassword: false,
      showNewPassword: false,
      showNewPasswordConfirm: false,
      changePassForm: false,
      logo: Logo,
      passwordPattern: /^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,20}$/,
      rules: {
        required: value => (!!value || value === 0) || '必須入力です',
        password: value => {
          const pattern = state.passwordPattern
          return pattern.test(value) || 'パスワードが簡単すぎるか、長すぎます 8～20文字、かつ半角英数字（大、小）、記号をそれぞれ1種類以上含むように入力してください。'
        },
        passConfirm: () => state.newPassConfirm === state.newPass || 'パスワードが一致しません'
      }
      
    })

    onMounted(() => {
      document.title = 'TEPSYS IoTSS'
			init()
    })

    // getter / setter
    
    function init() {
      try {
        var timerExecList = [];
        // ログイン認証完了までリトライ
        var timerid = setInterval(function () {
            Auth.currentUserInfo().then(async function (userInfo) {
              if (userInfo) {
                // ユーザID＝事業者ID
                state.privateServiceProviderId = userInfo.username;
                setServiceProviderId();
      
                // 施設情報取得APIを実行
                await store.doApiGetFacilityInfoData(userInfo);
                state.infoGeted = true
      
                // タイマークリア
                clearInterval(timerid);
      
                //タイマー（施設情報データstore保存待ち）
                var timerid2 = setInterval(function () {
                    if (Object.keys(store.getFacilityInfoDataStored()).length != 0) {
                        // storeから施設情報を取得する
                        getFacilityInfoData();
                        // タイマークリア
                        clearInterval(timerid2);
                    }
                }, 200)
                timerExecList.push(timerid2);
              }
            })
        }, 1000)
        timerExecList.push(timerid);
      
      } catch(error) {
        console.log(error);
        for (var timer in timerExecList) {
          clearInterval(timer);
        }
      }
    }

    /**
     * storeから事業者IDを取得する。
     */
    function setServiceProviderId() {
      store.setServiceProviderIdStored(state.privateServiceProviderId)
    }

    /**
     * storeから施設情報を取得する。
     */
    function getFacilityInfoData() {
        state.globalFacilityInfoData = [];
        for (var items in store.getFacilityInfoDataStored()) {
            state.globalFacilityInfoData.push(store.getFacilityInfoDataStored()[items]);
        }
    }

    /**
     * パスワード変更
     */
    async function passChange() {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user)
      await Auth.changePassword(user, state.nowPass, state.newPass).then(response => {
        console.log(response)
        alert('パスワード変更が完了しました。一度サインアウトします。');
        state.passChangeDialog = false
        this.$refs.changePassForm.reset()
        state.showNewPassword = false
        state.showNowPassword = false
        state.showNewPasswordConfirm = false
        Auth.signOut();
      }, () => {
        alert('パスワード変更に失敗しました。入力内容を確認し、再度お試しください。');
      }).catch(() => {
        alert('パスワード変更に失敗しました。入力内容を確認し、再度お試しください。');
      })
    }
    
		function signOut() {
			state.infoGeted = false
			Auth.signOut().then(() => {
			})
		}
		
    return {
      state,
      init,
      setServiceProviderId,
      getFacilityInfoData,
      passChange,
      signOut
    }
  },
  methods: {
	},
	watch: {
    'state.infoGeted': function (value) {
      if(!value) {
        this.init()
      }
    }
	}

};
</script>
