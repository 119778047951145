import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';

import vuetify from './plugins/vuetify';
import VueCompositionApi from '@vue/composition-api';

Amplify.configure(aws_exports);
Vue.use(VueCompositionApi);

Vue.config.productionTip = false

let languageDict = {
  ja:{
      'Sign in to your account' : 'アカウントにサインイン',
      'Username *' : 'ユーザー名',
      'Enter your username' : 'ユーザ名を入力してください',
      // 'Username *' : 'メールアドレス',
      // 'Enter your username' : 'メールアドレスを入力してください',
      'Password' : 'パスワード',
      'Enter your email address' : 'メールアドレスを入力してください',
      'Enter your password' : 'パスワードを入力してください',
      'Forgot your password?' : 'パスワードを忘れた場合',
      'Reset password' : 'パスワードのリセット',
      'Reset your password' : 'パスワードのリセット',
      'No account?' : 'まだアカウントがない場合',
      'Create account' : '新規登録',
      'Sign In' : 'サインイン',
      'Sign Out' : 'サインアウト',
      'Email Address *' : 'メールアドレス *',
      'Password *' : 'パスワード *',
      'Back to Sign In' : 'サインイン画面に戻る',
      'Send Code' : '認証コードを送信する',
      'Submit' : '実行する',
      'Verification code' : '認証コード（verification code）',
      'Enter code' : 'メール送信された認証コードを入力してください',
      'New password' : '新しいパスワード',
      'Enter your new password' : '新しいパスワードを入力してください',
  }
}
Amplify.I18n.putVocabularies(languageDict)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
