<template>
  <div id="dashboard">
    <v-container>

      <h1 class="page-title mb-3">ダッシュボード</h1>

      <h2 class="pb-2 pt-10">事業者名</h2>
      <div class="pb-10">
        <v-card>
          <h2 class="pa-5">{{state.providerName}}</h2>
        </v-card>
      </div>

      <h2 class="pb-2 pt-4">ご利用状況<v-btn icon small class="help-btn" @click="helpDialog = true"><v-icon size="20px">mdi-help-circle-outline</v-icon></v-btn></h2>
      <div class="l-dashboard">
        <v-row class="lighten-4" justify="start" align-content="center">
          <v-col cols=12 sm=6 md=6 lg=4 v-for="items in state.globalFacilityInfoData" v-bind:key = items.facility_id>
            <v-card color="white">
              <v-card-title class="border-bottom">
                <div class="item-inline">{{items.facility_name}}</div>
                <v-btn class="item-inline card-btn" outlined color="info" small :to="{name:'FacilityDetailId',params:{id:items.facility_id}}">
                  チャート
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="item-flex">
                <div class="card-user width-50-p">
                  <div class="px-1">現在のご利用人数</div>
                  <div class="card-user-text"><span>{{items.people ? items.people : 0}}人</span></div>
                </div>
                <div class="card-status width-50-p">
                  <div class="px-1">ステータス</div>
                  <div class="card-status-img" v-if="!items.people && items.people !== 0"><img src="@/assets/dashboard/maintenance.png" width="48px" height="48px" /></div>
                  <div class="card-status-img" v-else-if="items.mainte_status_id == 1"><img src="@/assets/dashboard/maintenance.png" width="48px" height="48px" /></div>
                  <div class="card-status-img" v-else-if="items.mainte_status_id == 2"><img src="@/assets/dashboard/unavailable.png" width="48px" height="48px" /></div>
                  <div class="card-status-img" v-else-if="isOffhours(items)"><img src="@/assets/dashboard/offhours.png" width="48px" height="48px" /></div>
                  <div class="card-status-img" v-else-if="items.mainte_status_id == 0 && items.people <= items.threshold_congestion_1" ><img src="@/assets/dashboard/use_human_blue_1.png" width="48px" height="48px" /></div>
                  <div class="card-status-img" v-else-if="items.mainte_status_id == 0 && items.threshold_congestion_2 <= items.people"><img src="@/assets/dashboard/use_human_red_3.png" width="48px" height="48px" /></div>
                  <div class="card-status-img" v-else-if="items.mainte_status_id == 0 && items.threshold_congestion_1 < items.people && items.people < items.threshold_congestion_2"><img src="@/assets/dashboard/use_human_yellow_2.png" width="48px" height="48px" /></div>
                  <div v-else></div>
                </div>
                <!--<v-row class="align-center flx-block-content pl-7 pr-2">-->
                <!--  <v-col class="flex-grow-0">-->
                <!--    <p v-if="items.mainte_status_id == 1"><img src="@/assets/dashboard/maintenance.png" /></p>-->
                <!--    <p v-else-if="items.mainte_status_id == 2"><img src="@/assets/dashboard/unavailable.png" /></p>-->
                <!--    <p v-else-if="isNotUpdate(items.report_time)"><img src="@/assets/dashboard/maintenance.png" /></p>-->
                <!--    <p v-else-if="isOffhours(items)"><img src="@/assets/dashboard/offhours.png" /></p>-->
                <!--    <p v-else-if="items.mainte_status_id == 0 && items.people <= items.threshold_congestion_1" ><img src="@/assets/dashboard/use_human_blue_1.png" /></p>-->
                <!--    <p v-else-if="items.mainte_status_id == 0 && items.threshold_congestion_2 <= items.people"><img src="@/assets/dashboard/use_human_red_3.png" /></p>-->
                <!--    <p v-else-if="items.mainte_status_id == 0 && items.threshold_congestion_1 < items.people && items.people < items.threshold_congestion_2"><img src="@/assets/dashboard/use_human_yellow_2.png" /></p>-->
                <!--    <p v-else></p>-->
                <!--  </v-col>-->
                <!--  <v-col>-->
                <!--    <router-link :to="{name:'FacilityDetailId',params:{id:items.facility_id}}"><span style="font-size:1.1em">■{{items.facility_name}}</span><div v-if="items.mainte_status_id == 0 && !isNotUpdate(items.report_time) && !isOffhours(items) && items.people">{{items.report_time|dateUpdated}}</div><div v-if="items.mainte_status_id == 0 && !isNotUpdate(items.report_time) && !isOffhours(items) && items.people">利用人数：<span style="font-size:1.1em">{{items.people}}</span>人</div></router-link>-->
                <!--  </v-col>-->
                <!--</v-row>-->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!--<h4>凡例</h4>-->
      <!--<div class="l-dashboard">-->
      <!--  <v-row>-->
      <!--    <v-col>-->
      <!--      <p><img src="@/assets/dashboard/guide.png" class="guide" /></p>-->
      <!--    </v-col>-->
      <!--  </v-row>-->
      <!--</div>-->
    </v-container>

    <v-dialog v-model="helpDialog" width="640px">
      <v-card>
        <v-card-title class="dialog-title px-0 py-1">
          <div class="width-100-p">
            <v-toolbar flat>
              <v-toolbar-title>
                <span>ヘルプ</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="helpDialog = false"><v-icon size="36px">mdi-close</v-icon></v-btn>
            </v-toolbar>
          </div>
        </v-card-title>

        <v-card-text class="px-4">
          <div class="font-size-16 pt-7 pb-3">各施設の状況をステータスアイコンで把握できます。</div>

          <div class="help-item">
            <div class="item-inline"><img src="@/assets/dashboard/use_human_blue_1.png" width="48px" height="48px" /></div>
            <div class="help-item-text">スペースに余裕があります。</div>
          </div>

          <div class="help-item">
            <div class="item-inline"><img src="@/assets/dashboard/use_human_yellow_2.png" width="48px" height="48px" /></div>
            <div class="help-item-text">スペースが混み合っています。</div>
          </div>

          <div class="help-item">
            <div class="item-inline"><img src="@/assets/dashboard/use_human_red_3.png" width="48px" height="48px" /></div>
            <div class="help-item-text">スペースが埋まっています。少々お待ちください。</div>
          </div>

          <div class="help-item">
            <div class="item-inline"><img src="@/assets/dashboard/offhours.png" width="48px" height="48px" /></div>
            <div class="help-item-text">営業時間外です。</div>
          </div>

          <div class="help-item">
            <div class="item-inline"><img src="@/assets/dashboard/maintenance.png" width="48px" height="48px" /></div>
            <div class="help-item-text">調整中です。</div>
          </div>

          <div class="help-item-no-border">
            <div class="item-inline"><img src="@/assets/dashboard/unavailable.png" width="48px" height="48px" /></div>
            <div class="help-item-text">スペースは現在利用できません。</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

.help-btn {
  vertical-align: baseline;
}

.help-item {
  border-bottom: solid 1px #afafaf;
  padding: 16px 0;
}

.help-item-no-border {
  padding: 16px 0;
}

.help-item-text {
  display: inline-block !important;
  padding: 16px 16px;
  vertical-align: bottom;
  font-size: 16px;
}

.card-btn {
  position: absolute !important;
  right: 16px;
}

.card-user-text {
  font-size: 40px;
  height: 64px;
  line-height: 40px;
  text-align: center;
  padding: 12px 0;
  border-right: solid 1px #afafaf;
}

.card-status-img {
  border-left: solid 1px #afafaf;
  height: 64px;
  text-align: center;
  padding: 8px 0;
}

</style>

<script>
import { API } from 'aws-amplify';
import { reactive, computed, onMounted } from '@vue/composition-api';
import { store } from '../store/store.js';
import moment from "moment";
import constants from '../common/constants';

export default {
  name: 'Dashboard',
  components: {
  },
  data() {
    return {
      helpDialog: false,
      ServiceProviderList : {},
      facilityInfoData: [],
      testData: [],
      time: 60 * constants.TIME_INTERVAL
    }
  },
  created: async function () {
    await this.getFacilityInfoData()
    setInterval(() => { this.time-- }, 1000)
  },
  mounted: async function () {
    console.log('cc')
    let cameraKeyArray = new Array()
    for (var data in this.state.globalFacilityInfoData) {
      cameraKeyArray.push(this.state.globalFacilityInfoData[data]["rule_name"]);
    }
    this.doApiGetLatestPeople(cameraKeyArray)
  },
  filters: {
    dateUpdated: function(reportTime) {
      moment.locale('ja', {
        weekdays: ["日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日"],
        weekdaysShort: ["日","月","火","水","木","金","土"],
      });
      if (!reportTime) { return ""; }
      var format = 'M月D日 (ddd) H時m分現在';
      var dateStr = moment(reportTime).format(format);
      return dateStr;

    }
  },
  methods: {
  },
  computed: {
    isOffhours: function() {
      return function(items) {
        var format = 'HH:mm:ss';
        var nowTimeStr = moment().format(format);
        if(moment(nowTimeStr, format).isBetween(moment(items.open_time1, format), moment(items.close_time1, format))){
          return false;
        }
        if(moment(nowTimeStr, format).isBetween(moment(items.open_time2, format), moment(items.close_time2, format))){
          return false;
        }
        if(moment(nowTimeStr, format).isBetween(moment(items.open_time3, format), moment(items.close_time3, format))){
          return false;
        }
        return true;
      }
    },
    isNotUpdate: function() {
      return function(reportTime) {
        let dateComp = new Date();
        dateComp.setMinutes(dateComp.getMinutes() - constants.NOT_UPDATED_PEOPLE_MINUTES);
        if(moment(reportTime) < moment(dateComp)){
          return true;
        } else {
          return false;
        }
      }
    }
  },
  watch: {
    time: async function(v) {
      if (v <= 0) {
        await this.getFacilityInfoData()
        let cameraKeyArray = new Array();
        for (var data in this.state.globalFacilityInfoData) {
          cameraKeyArray.push(this.state.globalFacilityInfoData[data]["rule_name"]);
        }
        this.doApiGetLatestPeople(cameraKeyArray)
        this.time = 60 * constants.TIME_INTERVAL
      }
    }
  }

  // Vue Composition API
  ,setup(props, context) {
    const route = context.root.$route

    const state = reactive({
      globalFacilityInfoData: [],
      peopleTimer: null,
      store,
      providerName: computed(
        () => (Object.values(state.globalFacilityInfoData)[0]) ? Object.values(state.globalFacilityInfoData)[0]["service_provider_name"] : ""
      )
    })

    onMounted(async () => {
      //doExecDashBoardProc();
    })

    /**
    * storeから施設情報を取得する。
    */
    async function getFacilityInfoData() {
      const selfState = state
      state.globalFacilityInfoData = [];
      await Promise.all(store.getFacilityInfoDataStored().map(async function (v) {
        await selfState.globalFacilityInfoData.push(v)
      }))
    }

    /**
    * ダッシュボードの初期表示を行う。
    */
    // function doExecDashBoardProc() {

    //   try {
    //     var timerExecList = [];

    //     // タイマー（認証待ち）
    //     var timerid2 = setInterval(function () {

    //       Auth.currentUserInfo().then((userInfo) => {
    //         if (userInfo != null) {
    //           // タイマークリア
    //           clearInterval(timerid2);

    //           //タイマー（施設情報データstore保存待ち）
    //           var timerid = setInterval(function () {
    //             if (Object.keys(store.getFacilityInfoDataStored()).length != 0) {
    //               // storeから施設情報を取得する
    //               getFacilityInfoData();
    //               // タイマークリア
    //               clearInterval(timerid);

    //               // 現在人数取得APIを実行
    //               try {
    //                 let cameraKeyArray = new Array();
    //                 for (var data in state.globalFacilityInfoData) {
    //                   cameraKeyArray.push(state.globalFacilityInfoData[data]["rule_name"]);
    //                 }
    //                 doApiGetLatestPeople(cameraKeyArray);
    //                 state.peopleTimer = setInterval(function(){
    //                   doApiGetLatestPeople(cameraKeyArray);
    //                 }, 1000 * 60 * constants.TIME_INTERVAL);

    //               } catch(error) {
    //                 store.clearCurrentPeopleStored();
    //                 let errmsg = "現在人数の取得に失敗しました。";
    //                 console.log(errmsg + error);
    //                 //alert(errmsg)
    //               }
    //             }
    //           }, 200)
    //           timerExecList.push(timerid);
    //         }
    //       })
    //     }, 1000)
    //     timerExecList.push(timerid2);

    //   } catch(error) {
    //     console.log(error);
    //     for (var timer in timerExecList) {
    //       clearInterval(timer);
    //     }
    //   }
    // }

    /**
    * 現在人数の取得処理を実行する。
    */
    function doApiGetLatestPeople(cameraKeyArray) {
      if(route.path !== '/dashboard'){
        clearInterval(state.peopleTimer);
        return;
      }
      let now = moment();

      // API Gatewayの名称
      const apiName = 'iotsstmdevtest201211';
      // API Gatewayの設定パス
      const apiPathGetLatestPeople =  '/GetLatestPeople';
      const paramPath = apiPathGetLatestPeople;

      API.post(
        apiName, paramPath,
        {
          headers: {},
          response: true,
          body: {
            cameraKey: cameraKeyArray,
            startDt: moment(now).subtract(constants.NOT_UPDATED_PEOPLE_MINUTES + 1, 'minutes').format(),
            endDt: moment(now).add(1, 'minutes').format(),
          }

        }).then(response => {
          let decodedJsonData = JSON.parse(JSON.stringify(response.data));
          // 現在人数情報のstore保存
          for (let i in state.globalFacilityInfoData){
            decodedJsonData.forEach(dataPeople => {
              if (dataPeople.Items && dataPeople.Items.length && state.globalFacilityInfoData[i].rule_name == dataPeople.Items[0].rule_name) {
                var newData = state.globalFacilityInfoData[i];
                newData.people = dataPeople.Items[0].people;
                newData.report_time = dataPeople.Items[0].report_time;
                state.globalFacilityInfoData.splice(i, 1, newData);
              }
            });
          }
        }).catch(error => {
          //alert("現在人数情報の取得処理でエラーが発生しました。");
          store.clearCurrentPeopleStored();
          console.log(error);
        })
      }

      return {
        state,
        getFacilityInfoData,
        doApiGetLatestPeople,
      }
    }
  }

  </script>
