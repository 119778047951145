<template>
  <div class='facility'>
    <v-container>
      <h1 class="page-title mb-3">混雑度チャート</h1>
  
      <h2 class="pb-2 pt-10">施設名</h2>
      <div class="pb-10 max-width-480">
        <v-card>
          <v-select class="px-3" v-model="state.selectFacility" item-text="facility_name" item-value="rule_name"
              :items="state.globalFacilityInfoData" @change="getChartData()"
              return-object/>
        </v-card>
      </div>
      <v-card class="" color="white">
        <v-card-title class="border-bottom" :class="{'pa-2': $vuetify.breakpoint.xs, 'pa-4': !$vuetify.breakpoint.xs}">
          <v-btn class="item-inline mr-1" outlined small :color="chartType === 0 ? 'info' : 'grey'" @click="chartType = 0;chartDateChange(0)" :class="{'px-1': $vuetify.breakpoint.xs}">本日</v-btn>
          <v-btn class="item-inline mr-1" outlined small :color="chartType === 1 ? 'info' : 'grey'" @click="chartType = 1;chartDateChange(1)" :class="{'px-1': $vuetify.breakpoint.xs}">昨日</v-btn>
          <v-btn class="item-inline mr-1" outlined small :color="chartType === 2 ? 'info' : 'grey'" @click="chartType = 2;chartDateChange(2)" :class="{'px-1': $vuetify.breakpoint.xs}">過去1週間</v-btn>
          <!--<v-btn class="item-inline mr-1" outlined small :color="chartType === 2 ? 'info' : 'grey'" @click="chartType = 2;chartDateChange(2)">過去14日間</v-btn>-->
          <!--<v-btn class="item-inline mr-1" outlined small :color="chartType === 3 ? 'info' : 'grey'" @click="chartType = 3;chartDateChange(3)">過去30日間</v-btn>-->
          <!--<v-btn class="item-inline mr-1" outlined small :color="chartType === 4 ? 'info' : 'grey'" @click="chartType = 4;chartDateChange(4)">カスタマイズ</v-btn>-->
          <div class="item-inline chart-date-box item-flex mr-2" :class="{'border-box': chartType != 9, 'border-box-info': chartType === 9}">
            <v-menu :ref="chartStartDate" v-model="chartStartDatePicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <div @click="chartStartDatePicker = true;chartType = 9" v-on="on" class="font-size-14 chart-date-text pl-1" :class="{'font-color-info': chartType === 9, 'border-bottom-info': chartStartDatePicker}">{{state.startDatepicker}}</div>
              </template>
              <v-date-picker type="date" v-model="state.startDatepicker" no-title scrollable locale="jp" @change="getChartData()">
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="chartStartDatePicker = false">OK</v-btn>
              </v-date-picker>
            </v-menu>
            <span class="px-2 font-size-14 chart-date-text" :class="{'font-color-info': chartStartDatePicker || chartEndDatePicker}">-</span>
            <v-menu :ref="chartStartDate" v-model="chartEndDatePicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <div @click="chartEndDatePicker = true;chartType = 9" v-on="on" class="font-size-14 chart-date-text pr-1" :class="{'font-color-info': chartType === 9, 'border-bottom-info': chartEndDatePicker}">{{state.endDatepicker}}</div>
              </template>
              <v-date-picker type="date" v-model="state.endDatepicker" no-title scrollable locale="jp" @change="getChartData()">
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="chartEndDatePicker = false">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <v-alert v-if="state.errorMessage" class="item-inline pa-0 ma-0" dense outlined type="error" height="28px" style="font-size:12px;line-height:28px;">{{state.errorMessage}}</v-alert>
        </v-card-title>
        <v-progress-linear indeterminate color="info" v-show="!state.loaded || !state.loadedPast"></v-progress-linear>
        <v-card-text :class="{'py-1': $vuetify.breakpoint.xs, 'px-0': $vuetify.breakpoint.xs, 'pa-4': !$vuetify.breakpoint.xs}">
          <chart v-if="state.loaded && state.loadedPast"
            :chartdata="state.chartdata"
            :options="!$vuetify.breakpoint.xs ? state.options : state.spOptions"
            :height="!$vuetify.breakpoint.xs ? 120 : 240"></chart>
          <!--<v-row justify="center">-->
          <!--  開始日：-->
          <!--  <Datepicker v-model="state.startDatepicker" name="datepicker1"-->
          <!--    :language="this.ja"-->
          <!--    :value="state.startDatepicker"-->
          <!--    :format="state.datePickerFormat">-->
          <!--  </Datepicker>-->
          <!--  開始時間：-->
          <!--  <VueTimepicker v-model="state.startTime" name="datepicker1"-->
          <!--    :format="state.timePickerFormat" hour-label="時" minute-label="分">-->
          <!--  </VueTimepicker>-->
          <!--</v-row>-->
          <!--<v-row justify="center">-->
          <!--  終了日：-->
          <!--  <Datepicker v-model="state.endDatepicker" name="datepicker2"-->
          <!--    :language="this.ja"-->
          <!--    :value="state.endDatepicker"-->
          <!--    :format="state.datePickerFormat">-->
          <!--  </Datepicker>-->
          <!--  終了時間：-->
          <!--  <VueTimepicker v-model="state.endTime" name="datepicker2"-->
          <!--    :format="state.timePickerFormat" hour-label="時" minute-label="分">-->
          <!--  </VueTimepicker>-->
          <!--</v-row>-->
        </v-card-text>
      </v-card>
      <!--<v-row justify="center" class="mt-6">-->
      <!--  <v-btn v-on:click="getChartData()">取得する</v-btn>-->
      <!--</v-row>-->
    </v-container>
  </div>
</template>

<script>
    import Chart from './Chart';
    // import Datepicker from "vuejs-datepicker";
    import ja from "vuejs-datepicker/dist/locale";
    // import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
    import { API, Auth } from 'aws-amplify';
    import moment from "moment";
    import { reactive, onMounted} from '@vue/composition-api';
    import { store } from '../store/store.js';

    export default {
        name: 'Facility',
        components: {
          Chart
        },
        props: {
        },
        created() {
        },
        data() {
            return {
                ja:ja,
                chartType: 0,
                chartStartDatePicker: false,
                chartEndDatePicker: false
            }
        },
        methods: {
          chartDateChange: function (value) {
            if (value === 0) {
              this.state.startDatepicker = moment().format('yyyy-MM-DD')
              this.state.endDatepicker = moment().format('yyyy-MM-DD')
            } else if (value === 1) {
              this.state.startDatepicker = moment().subtract(1, 'd').format('yyyy-MM-DD')
              this.state.endDatepicker = moment().subtract(1, 'd').format('yyyy-MM-DD')
            } else if (value === 2) {
              this.state.startDatepicker = moment().subtract(6, 'd').format('yyyy-MM-DD')
              this.state.endDatepicker = moment().format('yyyy-MM-DD')
            }
            this.getChartData()
          }
        },
        mounted() {
        },
        setup(props, context) {
            // Vue Composition API
            const route = context.root.$route

            const state = reactive({

                globalFacilityInfoData: [],
                selectFacility: null,
                startDatepicker: moment().format('yyyy-MM-DD'),
                endDatepicker: moment().format('yyyy-MM-DD'),
                datePickerFormat: 'yyyy-MM-dd',
                timePickerFormat: 'HH:mm',
                startTime: '00:00:00',
                endTime: '24:00:00',
                loaded: false,
                loadedPast: false,
                chartPast1th : [],
                chartPast2nd : [],
                chartPast3rd : [],
                chartPastCulcCount : 0,
                errorMessage: '',
                chartdata: {
                  labels: [],
                  datasets: [
                    {
                      label: '利用人数',
                      data:[],
                      borderColor: "#ff5252",
                      fill: false,
                      type: 'line',
                      lineTension: 0.3,
                    },
                    // {
                    //   label: '過去３週間平均人数',
                    //   data:[],
                    //   borderColor: "blue",
                    //   backgroundColor: [],
                    //   fill: false,
                    //   //type: 'bar',
                    //   type: 'line',
                    //   lineTension: 0.3,
                    // }
                    {
                      label: '過去３週間平均人数',
                      data: [],
                      backgroundColor: "#2196f3",
                      borderColor: "#2196f3",
                      borderWidth: 1,
                      type: 'bar'
                    }
                  ]
                },
                options: {
                  scales: {
                    xAxes: [{
                      type: 'time',
                      time: {
                        unit: 'hour'
                      },
                      scaleLabel: {
                        display: true,
                        labelString: '営業時間'
                      }
                    }],
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: '人数'
                      }
                    }]
                  }
                },
                spOptions: {
                  scales: {
                    xAxes: [{
                      type: 'time',
                      time: {
                        unit: 'hour'
                      },
                      scaleLabel: {
                        display: false,
                        labelString: '営業時間'
                      },
                      categoryPercentage: 0.95,
                      barPercentage: 0.95
                    }],
                    yAxes: [{
                      gridLines: {
                        display: false
                      },
                      ticks: {
                        display: false
                      },
                      scaleLabel: {
                        display: false
                      }
                    }]
                  }
                }
            })

            onMounted(() => {
              try {
                  var timerExecList = [];
                  
                  //タイマー
                  var timerid = setInterval(function () {
                      if (Object.keys(store.getFacilityInfoDataStored()).length != 0) {
                          // storeから施設情報を取得する
                          getFacilityInfoData();

                          state.selectFacility = state.globalFacilityInfoData[0];
                          if(route.params.id){
                            let found = state.globalFacilityInfoData.find(e => e.facility_id == route.params.id);
                            if(found){
                              state.selectFacility = found;
                            }
                          }
                          // チャート用データを取得
                          getChartData();

                          // タイマークリア
                          clearInterval(timerid);
                      }
                  }, 200)
                  timerExecList.push(timerid);

              } catch(error) {
                console.log(error);
                for (var timer in timerExecList) {
                  clearInterval(timer);
                }
              }
            })

            /**
             * storeから施設情報を取得する。
             */
            function getFacilityInfoData() {
              state.globalFacilityInfoData = [];
              for (var items in store.getFacilityInfoDataStored()) {
                  state.globalFacilityInfoData.push(store.getFacilityInfoDataStored()[items]);
              }
            }

            /**
             * チャート用データを取得する。
             */
            function getChartData(){
              state.errorMessage = '';
  
              if(moment(state.endDatepicker).diff(moment(state.startDatepicker), 'days') > 7){
                state.errorMessage = '開始日と終了日は１週間（７日）以内にしてください。';
                return;
              }

              state.loaded = false;
              state.loadedPast = false;
              var newDatasets = state.chartdata;
              newDatasets.datasets[0].data = [];
              newDatasets.datasets[1].data = [];
              state.chartdata = newDatasets;

              API.post(
                  'iotsstmdevtest201211',
                  '/GetChartData',
                  {
                    headers: {},
                    response: true,
                    body: {
                      cameraKey: state.selectFacility.rule_name,
                      startDt: moment(state.startDatepicker).format('yyyy-MM-DD') + 'T' + state.startTime + ':00' + '+0900',
                      endDt: moment(state.endDatepicker).format('yyyy-MM-DD') + 'T' + state.endTime + ':59' + '+0900'
                    }
                  }
                ).then(response => {
                  console.log(response)

                  let res = JSON.parse(JSON.stringify(response.data));
                  if(res.Items){
                    var resData = [];
                    res.Items.forEach(item => {
                      resData.push({x: item.report_time, y:item.people});
                    });
                    // 結果の反映
                    var newDatasets = state.chartdata;
                    newDatasets.datasets[0].data = resData;
                    state.chartdata = newDatasets;
                    state.loaded = true

                    // チャート用の過去データ取得する
                    getChartPastData();
                  }
                }).catch(error => {
                    state.errorMessage = 'チャートデータの取得処理でエラーが発生しました。';
                    state.loaded = true;
                    state.loadedPast = true;
                    console.log(error);
                })
            }

            /**
             * チャートの過去データを取得する。
             */
            async function getChartPastData() {
              const apiName = 'iotsstmdevtest201211';
              const param = {
                  startDt: moment(state.startDatepicker).format('yyyy-MM-DD'),
                  endDt: moment(state.endDatepicker).format('yyyy-MM-DD')
              }
              const url = '/Get3WeeksMeasure/' + state.selectFacility.rule_name
              let averageResponse = {}
              let averageArrayResponse = []
              let retChartPastData = []
              var newDatasets = state.chartdata;
              
              await Auth.currentAuthenticatedUser().then(async function (response) {
                const idToken = response.signInUserSession.idToken.jwtToken
                const myInit = {
                  headers: {},
                  response: true,
                  Authorization: idToken,
                  queryStringParameters: param
                }
                await API.get(apiName, url, myInit).then(result => {
                  averageArrayResponse = result.data.target_date_summary
                })
              })

              state.chartPastCulcCount = 0;
              state.loadedPast = false;
              
              await Promise.all(averageArrayResponse.map(async function (v) {
                averageResponse = v.latest_3weeks
                const targetDate = v.target_date
                const xArray = [...Array(24)].map((v, i) => i)
                state.chartPast1st = averageResponse[0] ? averageResponse[0].hourly_measures.map(v => {
                  return {
                    x: v.hour,
                    y: v.measure_people
                  }
                }) : [];
                state.chartPast2nd = averageResponse[1] ? averageResponse[1].hourly_measures.map(v => {
                  return {
                    x: v.hour,
                    y: v.measure_people
                  }
                }) : [];
                state.chartPast3rd = averageResponse[2] ? averageResponse[2].hourly_measures.map(v => {
                  return {
                    x: v.hour,
                    y: v.measure_people
                  }
                }) : [];
                state.errorMessage = '';
  
                await Promise.all(xArray.map(v => {
                  let aveY = 0;
                  const y1 = state.chartPast1st.length > 0 && state.chartPast1st.filter(w => w.x === v).length > 0 && (state.chartPast1st.find(w => w.x === v).y || state.chartPast1st.find(w => w.x === v).y === 0) ? state.chartPast1st.find(w => w.x === v).y : -1
                  const y2 = state.chartPast2nd.length > 0 && state.chartPast2nd.filter(w => w.x === v).length > 0 && (state.chartPast2nd.find(w => w.x === v).y || state.chartPast2nd.find(w => w.x === v).y === 0) ? state.chartPast2nd.find(w => w.x === v).y : -1
                  const y3 = state.chartPast3rd.length > 0 && state.chartPast3rd.filter(w => w.x === v).length > 0 && (state.chartPast3rd.find(w => w.x === v).y || state.chartPast3rd.find(w => w.x === v).y === 0) ? state.chartPast3rd.find(w => w.x === v).y : -1
                  const cnt = (y1 === -1 ? 0 : 1) + (y2 === -1 ? 0 : 1) + (y3 === -1 ? 0 : 1)
                  aveY = cnt !== 0 ? Math.ceil((((y1 === -1 ? 0 : y1) + (y2 === -1 ? 0 : y2) + (y3 === -1 ? 0 : y3)) / cnt)) : 0
                  retChartPastData.push({x: moment(targetDate).format('yyyy-MM-DD') + 'T' + (('00' + v).slice(-2)) + ':00:00+0900', y: aveY});
                }))
              }))
              
              retChartPastData.forEach(ret => {
                newDatasets.datasets[1].data.push(ret);
              });
              state.chartdata = newDatasets;
              state.loadedPast = true
              state.loaded = true;
            }

            return {
              state,
              getFacilityInfoData,
              getChartData,
              getChartPastData,
            }
        }
    }
</script>
<style scoped>

.chart-date-box {
  height: 28px;
  border-radius: 4px;
}

.chart-date-text {
  line-height: 26px
}

</style>