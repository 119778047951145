import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Dashboard from "../components/Dashboard.vue"
import Threshold from "../components/Threshold.vue"
import Maintenance from "../components/Maintenance.vue"
import Report from "../components/Report.vue"
import Sensor from "../components/SensorFix.vue"
import FacilityDetail from "../components/FacilityDetail.vue"
// import AddProvider from "../components/AddProvider.vue"
// import SensorDelete from "../components/SensorDelete.vue"

Vue.use(VueRouter)

const routes = [
 {
   path: '/',
   name: 'Home',
   component: Home,
   meta: { requiresAuth: true }
 },
 {
  // ダッシュボード
  path: '/dashboard',
  name: 'Dashboard',
  component: Dashboard,
  meta: { requiresAuth: true }
},
{
  // チャート画面
  path: '/detail',
  name: 'FacilityDetail',
  component: FacilityDetail,
  meta: { requiresAuth: true }
},
{
  // ダッシュボードから施設idを指定してチャート画面へ遷移
  path: '/detail/:id',
  name: 'FacilityDetailId',
  component: FacilityDetail,
  meta: { requiresAuth: true }
},
{
  // 閾値設定画面
  path: '/threshold',
  name: 'Threshold',
  component: Threshold,
  meta: { requiresAuth: true }
},
{
  // センサー値補正
  path: '/sensor',
  name: 'Sensor',
  component: Sensor,
  meta: { requiresAuth: true }
},
{
  // メンテナンス設定画面
  path: '/maintenance',
  name: 'Maintenance',
  component: Maintenance,
  meta: { requiresAuth: true }
},
{
  // 月間レポート画面
  path: '/report',
  name: 'Report',
  component: Report,
  meta: { requiresAuth: true }
}
// {
//   // 新規施設作成
//   path: '/new-provider',
//   name: 'AddProvider',
//   component: AddProvider,
//   meta: { requiresAuth: true }
// },
// {
//   // センサー値削除
//   path: '/sensor-delete',
//   name: 'SensorDelete',
//   component: SensorDelete,
//   meta: { requiresAuth: true }
// },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
