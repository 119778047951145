<template>
  <div class='maintenance'>
    <v-container>
      <h1 class="page-title mb-3">メンテナンス設定</h1>

      <h2 class="pb-2 pt-10">施設名</h2>
      <div class="pb-10 max-width-480">
        <v-card>
          <v-select class="px-3" v-model="state.grobalFacility" item-text="facility_name" item-value="rule_name"
              :items="state.globalFacilityInfoData" @change="changedValueFacility"
              return-object/>
        </v-card>
      </div>

      <h2 class="pb-2 pt-10">表示ステータス</h2>
      <div class="pb-10 max-width-480">
        <v-card>
          <v-select class="px-3" v-model="state.selectMainteStatus" item-text="mainte_status_name" item-value="mainte_status_name"
              :items="state.mainteStatusList"
              return-object/>
        </v-card>
      </div>

      <div class="pt-5">
        <v-btn v-on:click="doExecUpdate" class="font-bold font-size-20" color="info" x-large width="200px">設定を保存</v-btn>
        <v-progress-linear indeterminate color="info" style="width:200px" v-show="!state.loaded"></v-progress-linear>
      </div>
  
    </v-container>
  </div>
</template>

<script>
    import { API } from 'aws-amplify';
    import { reactive, onMounted} from '@vue/composition-api';
    import { store } from '../store/store.js';

    export default {
        name: 'Maintenance',
        components: {
        },
        data() {
            return {
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
        }

        // Vue Composition API
        ,setup() {
            const state = reactive({
                globalFacilityInfoData: [],
                selectMainteStatus:[
                    { mainte_status_id: '0', mainte_status_name: '通常運用'}
                ],
                mainteStatusList:[
                    { mainte_status_id: '0', mainte_status_name: '通常運用'},
                    { mainte_status_id: '1', mainte_status_name: '調整中'},
                    { mainte_status_id: '2', mainte_status_name: '利用不可'}
                ],
                grobalFacility: '',
                loaded: true,
                store
            })

            onMounted(() => {
              try {
                  var timerExecList = [];
                //タイマー
                var timerid = setInterval(function () {
                    if (Object.keys(store.getFacilityInfoDataStored()).length != 0) {
                        // storeから施設情報を取得する
                        getFacilityInfoData();
                       // タイマークリア
                        clearInterval(timerid);
                    }
                }, 200)
                timerExecList.push(timerid);

              } catch(error) {
                console.log(error);
                for (var timer in timerExecList) {
                  clearInterval(timer);
                }
              }
            })

            function doExecUpdate() {
              state.loaded = false;
              if (state.grobalFacility === undefined || state.grobalFacility == '') {
                alert("施設名を選択してください。");
                state.loaded = true;
                return;
              }
              if (state.selectMainteStatus === undefined || state.selectMainteStatus == '') {
                alert("表示ステータスを選択してください。");
                state.loaded = true;
                return;
              }
              var updFlg = false;
              var currentServiceProviderId = -1;
              var currentFacilityId = -1;
              for (var data in state.globalFacilityInfoData) {
                if (state.globalFacilityInfoData[data]["rule_name"] == state.grobalFacility.rule_name) {
                  currentServiceProviderId = state.globalFacilityInfoData[data]["service_provider_id"];
                  currentFacilityId = state.globalFacilityInfoData[data]["facility_id"];
                  // 更新
                  state.globalFacilityInfoData[data]["mainte_status_id"] = state.selectMainteStatus.mainte_status_id;
                  state.globalFacilityInfoData[data]["mainte_status_name"] = state.selectMainteStatus.mainte_status_name;
                  updFlg = true;
                  break;
                }
              }
              if (!updFlg) {
                alert("更新対象がありません。\n更新処理を中断します。")
                state.loaded = true;
                return;
              }

              // API Gatewayの名称
              let apiName = 'iotsstmdevtest201211';
              // API Gatewayの設定パス
              let apiPath = '/UpdateFacilityInfo';
              // オプション
              let myInit = {
                  headers: {},
                  response: true,
                  body: {
                    service_provider_id: String(currentServiceProviderId),
                    facility_id: String(currentFacilityId),
                    mainte_status_id: String(state.selectMainteStatus.mainte_status_id),
                    mainte_status_name: String(state.selectMainteStatus.mainte_status_name)
                  }
              };
              // DBデータ更新
              API.put(apiName, apiPath, myInit).then(result => {
                  if (result.data) {
                    alert("更新処理が正常に完了しました。");
                  } else {
                    alert("更新処理に失敗しました。");
                    console.log(result.data.error);
                  }
                  state.loaded = true;
              }).catch(err => {
                  alert("更新処理で想定外エラーが発生しました。");
                  console.log(err);
                  state.loaded = true;
              })
            }

            /**
             * storeから施設情報を取得する。
             */
            function getFacilityInfoData() {
              state.globalFacilityInfoData = [];
              for (var items in store.getFacilityInfoDataStored()) {
                  state.globalFacilityInfoData.push(store.getFacilityInfoDataStored()[items]);
              }
            }

            /**
             * 施設名プルダウン選択変更時。
             */
            function changedValueFacility() {
              let found = state.mainteStatusList.find(e => e.mainte_status_id == state.grobalFacility.mainte_status_id);
              state.selectMainteStatus = found;
            }

            return {
                state,
                getFacilityInfoData,
                doExecUpdate,
                changedValueFacility
            }
        }
    }
</script>
<style scoped>

</style>>