<template>
    <div class='report'>
    <v-container>
      <div class="page-title mb-3">
        <h1 class="item-inline">月間レポート</h1>
        <v-btn color="info" depressed style="float: right;" @click="clickCsvDl">csvダウンロード</v-btn>
      </div>

      <v-row>
        <v-col cols=12 sm=6 class="pt-10">
          <h2 class="pb-2">施設名</h2>
          <div class="pb-10">
            <v-card>
              <v-select class="px-3" v-model="state.selectFacility" item-text="facility_name" item-value="rule_name"
                  :items="state.globalFacilityInfoData" @change="changedValueFacility();if (state.selectedDate) { changeDate(state.selectedDate); }"
                  return-object/>
            </v-card>
          </div>
        </v-col>
        <v-col cols=12 sm=6 class="pt-10">
          <h2 class="pb-2">対象年月</h2>
          <div class="pb-10">
            <v-card height="">
              <v-menu v-model="datepicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field id="course-change-hope-date" readonly v-on="on" @focus="errorFlag = false" v-model="state.selectedDate" @change="changeDate" class="px-3"></v-text-field>
                </template>
                <v-date-picker type="month" v-model="state.selectedDate" no-title scrollable locale="jp" @change="changeDate">
                  <v-spacer></v-spacer>
                  <v-btn id="course-change-date-ok-btn" depressed color="primary" @click="datepicker = false">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-card>
          </div>
            </v-col>
        </v-row>

      <h2 class="pb-2">レポート</h2>
      <div justify="center" class="width-100" >
            <v-data-table
                :headers="state.tableHeaders"
                :items="state.tableItems"
                :items-per-page="40"
                :hide-default-footer="false"
                :footer-props="{'items-per-page-text':'1ページ毎のレコード数', itemsPerPageOptions: [-1]}"
                :loading="state.loading"
                loading-text="読込中"
                no-data-text="対象データはありません。"
                class="elevation-1"
                >
            </v-data-table>
      </div>
    </v-container>
    </div>
</template>

<script>
    import { reactive, onMounted} from '@vue/composition-api';
    import { store } from '../store/store.js';
    import moment from "moment";
    import { API, Auth } from 'aws-amplify';

    export default {
        name: 'Report',
        data() {
            return {
              datepicker: false
            }
        },
        methods: {
          changeDate: function (value) {
            this.state.selectedMonth = moment(value + '-01').month() + 1
            this.state.selectedYear = moment(value + '-01').year()
            console.log(value, this.state.selectedMonth)
            this.getReportData()
          },
          clickCsvDl: function () {
            if (!this.state.selectFacility) {
              alert("施設名を選択してください。")
              return
            }
            const fileeader = this.state.tableHeaders.map(v => v.text)
            const filecols = this.state.tableItems.map(v => {
              return {
                a: v.date,
                b: v.maxpeople,
                c: v.limitover,
                d: v.limitoverminutes
              }
            })
            this.csvDownload('月間レポート_' + this.state.selectFacility.facility_name + '_' + this.state.selectedDate, fileeader, filecols)
          }
        },
        // Vue Composition API
        setup() {
            const state = reactive({
                globalFacilityInfoData: [],
                selectFacility: null,
                redCongestion: null,
                yearList: getYears(),
                monthList:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                selectedYear: moment().year(),
                selectedMonth: moment().month() + 1,
                selectedDate: moment().format('YYYY-MM'),
                loading: false,
                tableHeaders: [
                    { text: "日付", value: "date" },
                    { text: "ピーク人数", value: "maxpeople" },
                    { text: "上限閾値超過回数", value: "limitover" },
                    { text: "超過時間（分）", value: "limitoverminutes" }
                ],
                tableItems: [],
                store
            })
            onMounted(() => {
                try {
                    var timerExecList = [];
                    //タイマー
                    var timerid = setInterval(function () {
                        if (Object.keys(store.getFacilityInfoDataStored()).length != 0) {
                            // storeから施設情報を取得する
                            getFacilityInfoData();

                            // タイマークリア
                            clearInterval(timerid);
                        }
                    }, 200)
                    timerExecList.push(timerid);

                } catch(error) {
                    console.log(error);
                    for (var timer in timerExecList) {
                        clearInterval(timer);
                    }
                }
            })

            /**
             * レポート用データを取得する。
             */
            function getReportData() {

                if (state.selectFacility == '' || state.selectFacility == null) {
                    alert("施設名を選択してください。");
                    return;
                }
                state.loading = true;
                state.tableItems = [];

                // 当月の始終日
                let ymdStart = state.selectedYear + "-" + ('00' + state.selectedMonth).slice(-2) + "-" + "01";
                let ymdEnd = moment(ymdStart).add(1, 'months').subtract(1, 'days').format('yyyy-MM-DD');

                Auth.currentAuthenticatedUser().then(response => {
                    const idToken = response.signInUserSession.idToken.jwtToken
                    const apiName = 'iotsstmdevtest201211';
                    const param = {
                        cameraKey: state.selectFacility.rule_name,
                        startDt: ymdStart,
                        endDt: ymdEnd
                    }
                    const myInit = {
                      headers: {},
                      response: true,
                      Authorization: idToken,
                      queryStringParameters: param
                    }
                    const url = '/GetReportData'
                    API.get(apiName, url, myInit).then(result => {
                        console.log(result.data)
                        state.tableItems = result.data.Items.map(function (v) {
                            return {
                                date: moment(v.report_date).format('MM月DD日'),
                                maxpeople:v.peak_population,
                                limitover: v.limitover_timings,
                                limitoverminutes: v.limitover_times
                            }
                        })
                        state.loading = false;
                    }).catch(err => {
                        state.loading = false;
                        alert("レポートデータ取得処理でエラーが発生しました。");
                        console.log(err);
                    })
                })
            }

            /**
             * storeから施設情報を取得する。
             */
            function getFacilityInfoData() {
              state.globalFacilityInfoData = [];
              for (var items in store.getFacilityInfoDataStored()) {
                  state.globalFacilityInfoData.push(store.getFacilityInfoDataStored()[items]);
              }
            }

            /**
             * 年リストを取得する。
             */
            function getYears() {
                const goBackYears = 10;
                const currentYear = moment().year();
                return [...Array(goBackYears + 1).keys()].map(x => currentYear - x);
            }

            /**
             * 施設名プルダウン選択変更時。
             */
            function changedValueFacility() {
              let found = state.globalFacilityInfoData.find(e => e.facility_id == state.selectFacility.facility_id);
              state.redCongestion = found.threshold_congestion_2;
            }

            // csvダウンロード
            // colTitle = ['A', 'B']
            // colContent = [
            //   ['A-1', 'B-1'],
            //   ['A-2', 'B-2']
            // ]
            function csvDownload (fileTitle, colTitle, colContent) {
              let csv = '\ufeff' + '"' + colTitle.join('","') + '"' + '\n'
              colContent.map(v => {
                csv += '"' + Object.values(v).join('","') + '"' + '\n'
              })
              const blob = new Blob([csv], { type: 'text/csv' })
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = fileTitle + '_' + moment().format('YYYYMMDD') + '.csv'
              link.click()
            }

            return {
                state,
                getFacilityInfoData,
                getReportData,
                changedValueFacility,
                csvDownload,
                getYears
            }
        }
    }
</script>
<style>
.v-data-table td {
    background: rgba(243, 243, 237, 0.966);
}
.v-data-table tr:nth-child(odd) td {
    background: #fff;
}
</style>>
