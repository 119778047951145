<template>
  <div class='threshold'>
    <v-container>
      <h1 class="page-title mb-3">閾値の設定</h1>
  
      <h2 class="pb-2 pt-10">施設名</h2>
      <div class="pb-10 max-width-480">
        <v-card>
          <v-select class="px-3" v-model="state.selectFacility" item-text="facility_name" item-value="rule_name"
              :items="state.globalFacilityInfoData" @change="changedValueFacility"
              return-object/>
        </v-card>
      </div>

      <h2 class="pb-2 pt-10">閾値</h2>
      <div class="l-dashboard">
        <v-row class="lighten-4" justify="start" align-content="center">
          <v-col cols=12 sm=6 md=4>
            <v-card color="white">
              <v-card-title class="border-bottom">
                <div class="item-inline font-bold">空き有り</div>
              </v-card-title>
              <v-card-text class="item-flex">
                <v-row class="lighten-4" justify="start" align-content="center">
                  <v-col cols=4 class="pa-0">
                    <div class="px-1">ステータス</div>
                    <div class="card-status-img"><img src="@/assets/dashboard/use_human_blue_1.png" width="48px" height="48px" /></div>
                  </v-col>
                  <v-col cols=8 class="pa-0">
                    <div class="px-1">人数（下限）</div>
                    <div class="card-user-text px-2">
                      <v-text-field v-model.number="state.selectedCongestion1" v-on:blur="checkCongestion1()" class="number-text-field" suffix="人"></v-text-field>
                      <!--<v-text-field v-model.number="state.selectedCongestion1" type="number" v-on:blur="checkCongestion1()" class="number-text-field" suffix="人"></v-text-field>-->
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols=12 sm=6 md=4>
            <v-card color="white">
              <v-card-title class="border-bottom">
                <div class="item-inline font-bold">普通</div>
              </v-card-title>
              <v-card-text class="item-flex">
                <v-row class="lighten-4" justify="start" align-content="center">
                  <v-col cols=4 class="pa-0">
                    <div class="px-1">ステータス</div>
                    <div class="card-status-img"><img src="@/assets/dashboard/use_human_yellow_2.png" width="48px" height="48px" /></div>
                  </v-col>
                  <v-col cols=8 class="pa-0">
                    <div class="px-1">人数（中間）</div>
                    <div class="font-size-28 text-center px-2 pt-8">
                      {{thresholdBetween}}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols=12 sm=6 md=4>
            <v-card color="white">
              <v-card-title class="border-bottom">
                <div class="item-inline font-bold">混雑</div>
              </v-card-title>
              <v-card-text class="item-flex">
                <v-row class="lighten-4" justify="start" align-content="center">
                  <v-col cols=4 class="pa-0">
                    <div class="px-1">ステータス</div>
                    <div class="card-status-img"><img src="@/assets/dashboard/use_human_red_3.png" width="48px" height="48px" /></div>
                  </v-col>
                  <v-col cols=8 class="pa-0">
                    <div class="px-1">人数（上限）</div>
                    <div class="card-user-text px-2">
                      <v-text-field v-model.number="state.selectedCongestion3" v-on:blur="checkCongestion3()" class="number-text-field" suffix="人"></v-text-field>
                      <!--<v-text-field v-model.number="state.selectedCongestion3" type="number" v-on:blur="checkCongestion3()" class="number-text-field" suffix="人"></v-text-field>-->
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="pt-5">
        <v-btn v-on:click="doExecUpdate" class="font-bold font-size-20" color="info" x-large width="200px">設定を保存</v-btn>
        <v-progress-linear indeterminate color="info" style="width:200px" v-show="!state.loaded"></v-progress-linear>
      </div>

    </v-container>
  </div>
</template>

<script>
    import { API } from 'aws-amplify';
    import { reactive, onMounted, computed} from '@vue/composition-api';
    import { store } from '../store/store.js';

    export default {
        name: 'Threshold',
        components: {
        },
        methods: {
        },
        computed: {
        },
        data() {
            return {
                congestionList1: [],
                congestionList3: [],
            }
        },
  
        // Vue Composition API
        setup() {
            const state = reactive({
                globalFacilityInfoData: [],
                selectFacility: null,
                selectedCongestion1: 0,
                selectedCongestion3: 0,
                loaded: true,
                store
            })
            const thresholdBetween = computed(() => 
              (
                (Number(state.selectedCongestion1) + 1) > (Number(state.selectedCongestion3) - 1) ? '' :
                (Number(state.selectedCongestion1) + 1) === (Number(state.selectedCongestion3) - 1) ? (Number(state.selectedCongestion1) + 1) + '人' :
                (Number(state.selectedCongestion1) + 1) + "～" + (Number(state.selectedCongestion3) - 1) + "人"
              )
            )

            onMounted(() => {
                try {
                  var timerExecList = [];
                  //タイマー
                  var timerid = setInterval(function () {
                      console.log(state.globalFacilityInfoData.length);
                      if (Object.keys(store.getFacilityInfoDataStored()).length != 0) {
                          getFacilityInfoData();

                          clearInterval(timerid);
                      }
                  }, 200)
                  timerExecList.push(timerid);

                } catch(error) {
                  console.log(error);
                  for (var timer in timerExecList) {
                    clearInterval(timer);
                  }
                }
            })

            /**
             * 閾値の設定を更新反映する。
             */
            function doExecUpdate() {
              state.loaded = false;
              if (state.selectFacility == '' || state.selectFacility == null) {
                alert("施設名を選択してください。");
                state.loaded = true;
                return;
              }
              if (Number(state.selectedCongestion3) - Number(state.selectedCongestion1) < 2) {
                alert("閾値の上限、下限の設定を確認してください。");
                state.loaded = true;
                return;
              }

              var updFlg = false;
              var currentServiceProviderId = -1;
              var currentFacilityId = -1;
              for (var data in state.globalFacilityInfoData) {
                if (state.globalFacilityInfoData[data]["rule_name"] == state.selectFacility.rule_name) {
                  currentServiceProviderId = state.globalFacilityInfoData[data]["service_provider_id"];
                  currentFacilityId = state.globalFacilityInfoData[data]["facility_id"];
                  // 更新
                  state.globalFacilityInfoData[data]["threshold_congestion_1"] = state.selectedCongestion1;
                  state.globalFacilityInfoData[data]["threshold_congestion_2"] = state.selectedCongestion3;
                  updFlg = true;
                  break;
                }
              }
              if (!updFlg) {
                alert("更新対象がありません。\n更新処理を中断します。");
                state.loaded = true;
                return;
              }

              // API Gatewayの名称
              let apiName = 'iotsstmdevtest201211';
              // API Gatewayの設定パス
              let apiPath = '/UpdateFacilityInfo';
              // オプション
              let myInit = {
                  headers: {},
                  response: true,
                  body: {
                    service_provider_id: String(currentServiceProviderId),
                    facility_id: String(currentFacilityId),
                    threshold_congestion_1: Number(state.selectedCongestion1),
                    threshold_congestion_2: Number(state.selectedCongestion3)
                  }
              };
              // DBデータ更新
              API.put(apiName, apiPath, myInit).then(result => {
                  if (result.data) {
                    alert("更新処理が正常に完了しました。");
                  } else {
                    alert("更新処理に失敗しました。");
                    console.log(result.data.error);
                  }
                  state.loaded = true;
              }).catch(err => {
                  alert("更新処理で想定外エラーが発生しました。");
                  console.log(err);
                  state.loaded = true;
              })
            }

            /**
             * storeから施設情報を取得する。
             */
            function getFacilityInfoData() {
              state.globalFacilityInfoData = [];
              for (var items in store.getFacilityInfoDataStored()) {
                  state.globalFacilityInfoData.push(store.getFacilityInfoDataStored()[items]);
              }
            }

            /**
             * 施設名プルダウン選択変更時。
             */
            function changedValueFacility() {
              for (var data in state.globalFacilityInfoData) {
                if (state.globalFacilityInfoData[data]["rule_name"] == state.selectFacility.rule_name) {
                  state.selectedCongestion1 = state.globalFacilityInfoData[data]["threshold_congestion_1"]
                  state.selectedCongestion3 = state.globalFacilityInfoData[data]["threshold_congestion_2"]
                  break;
                }
              }
            }

            /**
             * 閾値設定1のバリデーション。
             */
            function checkCongestion1() {
              if (state.selectedCongestion1 == "") {
                state.selectedCongestion1 = 0;
              }
            }

            /**
             * 閾値設定3のバリデーション。
             */
            function checkCongestion3() {
              if (state.selectedCongestion3 == "") {
                state.selectedCongestion3 = 0;
              }
            }

            return {
                state,
                getFacilityInfoData,
                doExecUpdate,
                thresholdBetween,
                changedValueFacility,
                checkCongestion1,
                checkCongestion3
            }
        }
    }
</script>
<style scoped>

.card-user-text {
  font-size: 40px;
  height: 64px;
  line-height: 40px;
  text-align: center;
  padding: 12px 0;
}

.card-status-img {
  border-right: solid 2px #afafaf;
  height: 64px;
  text-align: center;
  padding: 8px 0;
}

.number-text-field {
  font-size: 24px;
}

</style>>